@import "./normalize.css";

:root {
  --white: #fff;
  --color--primary: #7f42ff;
  --color--headings: #25323d;
  --color--text: #696969;
  --color--background: #faf8fd;
  --transition: all 0.3s ease-in-out;
  --border--radius: 40px;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

img {
  width: 100%;
}

/* Typography */

::selection {
  color: var(--white);
  background: var(--color--primary);
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  font-size: 1.8rem;
  color: var(--color--text);
  background: var(--color--background);
}

h1,
h2,
h3 {
  color: var(--color--headings);
  margin-bottom: 1rem;
  line-height: 1.1;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2.4rem;
}

p {
  margin-top: 0;
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 4.8rem;
  }

  h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 3.2rem;
  }
}

/* Links */

a {
  text-decoration: none;
}

.link {
  display: inline-block;
  position: relative;
  color: var(--color--primary);
  font-weight: 500;
}

.link .icon {
  margin-right: 1rem;
  vertical-align: middle;
}

.link:after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  bottom: -2px;
  position: relative;
  background-color: var(--color--primary);
  transition: var(--transition);
}

.link:hover:after {
  width: 100%;
}

.link-group {
  width: 44rem;
  max-width: 100%;
  height: 8.4rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 36px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
}

/* Icons */

.icon {
  width: 2.6rem;
  height: 2.6rem;
}

.icon--social {
  fill: #385e78;
}

.icon--project {
  fill: var(--color--primary);
}

.icon--skills {
  width: 35px;
  height: 35px;
}

.icon-container {
  width: 68px;
  height: 68px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.8rem;
  box-shadow: 0 70px 52px rgba(25, 25, 46, 0.04);
}

.icon-container--typescript {
  background: #f4eefb;
}

.icon-container--javascript {
  background: #fff2c5;
}

.icon-container--react {
  background: #ebf1ff;
}

.icon-container--graphql {
  background: #ffdcf3;
}

@media screen and (min-width: 768px) {
  .icon--skills {
    width: 55px;
    height: 55px;
  }

  .icon-container {
    width: 105px;
    height: 105px;
    border-radius: 4.3rem;
  }
}

/* Badge */

.badge {
  border-radius: 10.6rem;
  padding: 1rem 1.6rem;
  white-space: nowrap;
}

.badge--title {
  color: #fff;
  font-weight: 600;
  box-shadow: 0 8px 32px rgba(250, 118, 96, 0.2);
  background-image: linear-gradient(265.5deg, #bf3dff 1.85%, #7f28ff 98.53%);
}

/* Buttons */

.btn {
  white-space: nowrap;
  text-align: center;
  padding: 1.6rem 4.3rem;
  outline: 0;
  margin: 1rem 0;
  font-weight: 500;
  cursor: pointer;
  color: var(--white);
  border: 0;
  border-radius: var(--border--radius);
}

.btn--hero {
  display: inline-block;
  font-size: 2.2rem;
  border: 2px solid var(--color--primary);
  background-color: var(--white);
  background-image: linear-gradient(
    30deg,
    var(--color--primary) 50%,
    transparent 50%
  );
  background-image: -webkit-linear-gradient(
    30deg,
    var(--color--primary) 50%,
    transparent 50%
  );
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  transition: var(--transition);
}

.btn--hero:hover {
  background-position: 100%;
  color: var(--color--primary);
}

.btn--small {
  font-size: 1.5rem;
  font-weight: 600;
}

.btn--top {
  width: 5.5rem;
  height: 5.5rem;
  z-index: 100;
  transition: var(--transition);
  transform: translateY(100px);
  position: fixed;
  right: 15px;
  bottom: 15px;
  padding: 0;
  overflow: hidden;
  opacity: 0;
  font-size: 0;
  filter: drop-shadow(8px 13px 16px rgba(0, 0, 0, 0.1));
  border-radius: 50%;
  background: #4d4d4e;
}

.btn--show {
  opacity: 1;
  transform: translateY(0);
}

.btn--top .icon {
  width: 2.4rem;
  height: 2.4rem;
  margin: 0;
  vertical-align: middle;
  -webkit-transition: margin-bottom 0.2s ease-in-out;
  -moz-transition: margin-bottom 0.2s ease-in-out;
  transition: margin-bottom 0.2s ease-in-out;
}

.btn--top:hover .icon {
  margin-bottom: 5px;
}

@media screen and (min-width: 768px) {
  .btn--top {
    position: revert;
    width: 11.5rem;
    height: 4.5rem;
    font-size: 1.5rem;
    line-height: 2.5rem;
    display: inline;
    border-radius: var(--border--radius);
  }

  .btn--top .icon {
    width: 1.8rem;
    height: 1.8rem;
  }
}

/* Grids */

.grid {
  display: grid;
}

@media screen and (min-width: 768px) {
  .grid--1x2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .grid--1x2 {
    column-gap: 10rem;
  }
}

/* Blocks */

.block {
  padding: 5rem 2rem;
}

.block__heading {
  text-align: center;
  margin-top: 10rem;
  margin-bottom: 8rem;
}

.block--white {
  background: var(--white);
}

.container {
  max-width: 1120px;
  margin: 0 auto;
}

/* Navigation Bar */

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav__logo {
  width: 8rem;
  height: 8rem;
}

.nav__social {
  display: none;
}

.nav__social > a {
  margin-left: 3.5rem;
}

@media screen and (min-width: 768px) {
  .nav {
    justify-content: space-between;
  }

  .nav__logo {
    width: 6rem;
    height: 6rem;
  }

  .nav__social {
    display: revert;
  }
}

/* Hero */

.hero {
  padding: 6rem 2rem 0;
}

.hero__content {
  order: 1;
  margin-top: 5rem;
  text-align: center;
}

.hero__tagline {
  letter-spacing: 1px;
  margin: 2rem 0;
}

.hero__card-container {
  width: 100%;
  max-width: 447px;
  margin: 0 auto;
  position: relative;
}

.hero__card-container > .icon-container {
  position: absolute;
}

.hero__card-container > .icon-container--typescript {
  top: -16px;
  left: -32px;
}

.hero__card-container > .icon-container--javascript {
  top: 3px;
  right: -32px;
}

.hero__card-container > .icon-container--react {
  bottom: 20px;
  right: -32px;
}

.hero__card-container > .icon-container--graphql {
  bottom: -32px;
  left: 27px;
}

.hero__card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 4.35rem 0;
  box-shadow: 0 46px 35px rgba(25, 25, 46, 0.04);
  border-radius: 2.4rem;
  background: var(--color-card-hero);
}

.hero__photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  filter: drop-shadow(0 18px 36px rgba(0, 59, 108, 0.13));
}

.hero__city {
  color: #617483;
  font-size: 2.4rem;
  font-weight: 500;
  margin-top: 1rem;
}

@media screen and (min-width: 768px) {
  .hero {
    padding-left: 0;
  }

  .hero__content {
    text-align: left;
    order: 0;
    margin-top: 0;
    margin-right: 5rem;
    align-self: center;
  }

  .hero__heading {
    font-size: 4.2rem;
  }

  .hero__card-container > .icon-container--typescript {
    left: -52.5px;
  }

  .hero__card-container > .icon-container--react {
    top: 265px;
    bottom: 0;
  }

  .hero__card-container > .icon-container--graphql {
    top: 335px;
    bottom: 0;
  }
}

@media screen and (min-width: 1120px) {
  .hero {
    padding-right: 0;
  }
}

/* Project */

.project {
  width: 95%;
  max-width: 840px;
  margin: 0 auto 10rem;
}

.project__wrapper {
  position: relative;
}

.project__image {
  transform: translateZ(0);
  overflow: hidden;
  line-height: 0;
  border: 10px solid var(--white);
  border-radius: 3rem;
  box-shadow: 0 27px 124px rgba(0, 0, 0, 0.1);
}

.project__image img {
  transform-origin: 0 0;
  -webkit-transform: scale(1.01);
  -moz-transform: scale(1.01);
  transform: scale(1.01);
  -webkit-transition: transform 0.5s cubic-bezier(0.7, -0.39, 0.31, 1.38);
  -moz-transition: transform 0.5s cubic-bezier(0.7, -0.39, 0.31, 1.38);
  transition: transform 0.5s cubic-bezier(0.7, -0.39, 0.31, 1.38);
}

.project__image:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
}

.project__image-link:hover:after {
  width: 0;
}

.project__links {
  position: absolute;
  right: -20px;
  bottom: -20px;
}

.project__title {
  margin-top: 4rem;
}

.project__technology {
  font-weight: 600;
}

@media screen and (min-width: 768px) {
  .project {
    width: 80%;
  }
}

/* Contact */

.contact {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0;
}

.contact__text {
  font-size: 2.8rem;
  color: var(--color--headings);
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  .contact {
    flex-direction: row;
  }

  .contact__text {
    margin-right: 4rem;
    margin-bottom: 0;
  }
}

/* Footer */

.footer {
  text-align: center;
}

.footer__social > a {
  margin: 0 1.2rem;
}

.footer__copy-right {
  margin-top: 2rem;
  color: var(--color--headings);
}

@media screen and (min-width: 768px) {
  .footer {
    text-align: left;
  }

  .footer__social {
    display: none;
  }

  .footer__copy-right {
    margin: 0;
  }

  .footer__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
